<template>
  <div>
    <div class="main__top main__top-mgn">
      <div class="container container--mutualpr">
        <div class="main__title-wrap">
          <div class="">
            <h1 class="main__title">ВП на завтра</h1>
            <button
              class="link-blue js-btnPopup"
              data-popup="popupVideoInstruction"
              @click="showVideoModal()"
            >
              Видео инструкция
            </button>
          </div>
          <button
            v-if="currentUser"
            @click="showingAuthModal(true)"
            class="btn--orange main__btn--login"
          >
            Создать профиль ВП
          </button>
        </div>
        <div class="main__top-flex is-desktop">
          <div class="main__item main__item--bg">
            <ul class="main__item-list">
              <h3 class="main__item-title">Правила сервиса</h3>
              <li class="main__item-point">Дата ВП только завтрашняя.</li>
              <li class="main__item-point">Обмен рекламой в сторис 45 секунд.</li>
              <li class="main__item-point">Не более 2 ВП за день.</li>
              <li class="main__item-point">Партнеры для ВП подбираются системой.</li>
              <li class="main__item-point">
                Обман с охватами и статистикой карается баном на сайте.
              </li>
              <li class="main__item-point">
                При активации поиска до 17:00 по Москве система постарается подобрать вам до 10
                потенциальных партнеров, а после 17:00 — всего до 7. Поэтому мы рекомендуем
                активировать поиск утром.
              </li>
              <li class="main__item-point">
                Пользоваться сервисом может только блогер, у которого должно быть не меньше 1.000
                подписчиков, не паблик и не коммерческий аккаунт
              </li>
            </ul>
            <div class="main__item-footer">
              <p class="main__item-text">
                <span class="main__item-text--bold">{{ countVpWeekly }} ВП</span> было создано за
                последние 7 дней
              </p>
            </div>
          </div>
          <div class="main__item main__item--video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1AjKOa_wFq4"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <button
              class="main__item-btn js-btnPopup"
              data-popup="popupInstruction"
              @click="() => (infoShow = true)"
            >
              Инструкция текстом
            </button>
          </div>
        </div>
      </div>

      <p class="main__mobi-text">{{ countVpWeekly }} ВП было создано за последние 7 дней</p>
      <div class="main__filter to-desktop">
        <h2 class="main__filter-title">Интервал охватов ваших сторис</h2>
        <div class="main__filter-flex">
          <div class="main__filter-row">
            <span
              class="main__filter-item"
              @click="() => setFilter(filter.id)"
              v-bind:class="{ act: filter.id === currentFilterId }"
              v-for="filter in mobileFilter1"
              :key="filter.id"
            >
              {{ shortMobile(filter.name) }}
            </span>
          </div>
          <div class="main__filter-row">
            <span
              class="main__filter-item"
              @click="() => setFilter(filter.id)"
              v-bind:class="{ act: filter.id === currentFilterId }"
              v-for="filter in mobileFilter2"
              :key="filter.id"
            >
              {{ shortMobile(filter.name) }}
            </span>
          </div>
        </div>
        <button
          type="button"
          class="link-blue js-btnPopup"
          data-popup="popupLaw"
          @click="showRulesModal()"
        >
          Правила сервиса
        </button>
      </div>
      <div class="main__filter is-desktop">
        <h2 class="main__filter-title">Выберите интервал ваших охватов сторис</h2>
        <div class="main__filter-flex">
          <span
            class="main__filter-item"
            @click="() => setFilter(filter.id)"
            v-bind:class="{ act: filter.id === currentFilterId }"
            v-for="filter in filters"
            :key="filter.id"
          >
            {{ filter.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="main__content" v-if="showLoader">
      <loader />
    </div>

    <div class="main__content" v-else>
      <div class="container" v-if="accounts.length > 0">
        <h2 class="pr__h2">Премиум аккаунты</h2>
        <p class="pr__descr">
          У этих аккаунтов есть охваты и статистика, подтвержденные скриншотами.
        </p>
        <div class="card-wrapper pr--prem">
          <template v-for="account in premiumCards">
            <Card :account="account" :key="`${account.id}-shC1Kdpld76y7e`" />
          </template>
        </div>

        <button type="button" class="btn--white main__btn--more" @click="showingAuthModal(true)">
          Показать еще
        </button>
        <h2 class="pr__h2 pr__h2--alone">Обычные аккаунты</h2>
        <div class="card-wrapper">
          <template v-for="account in defCards">
            <Card :account="account" :key="`${account.id}-shCKdjh76y7e`" />
          </template>
        </div>
      </div>
      <div class="container text-center" v-else>
        В этой категории сегодня пока нет аккаунтов. Заходите позже 🙂
      </div>
    </div>
    <Modal
      :show="infoShow"
      @hide="() => (infoShow = false)"
      title="Инструкция текстом"
      wrapClass="popup-instruction__wrap"
    >
      <div class="popup-instruction__content">
        <ul class="popup-instruction__list">
          <li class="popup-instruction__item">Регистрируетесь на сервисе.</li>
          <li class="popup-instruction__item">Заполняете свою карточку и верифицируете аккаунт.</li>
          <li class="popup-instruction__item">Заходите в ВП на завтра.</li>
          <li class="popup-instruction__item">
            Выбираете партнера для ВП и отправляете ему запрос через наш сервис. Можно выбрать
            нескольких партнеров.
          </li>
          <li class="popup-instruction__item">
            Ожидаете ответ. Рекомендуем заходить несколько раз в день, чтобы не пропустить самые
            крутые предложения.
          </li>
          <li class="popup-instruction__item">
            Если ваш партнер по ВП согласился (это будет указано отдельно на странице), то жмете
            кнопку «Написать партнеру», копируете промокод и пишете ему в директ.
          </li>
          <li class="popup-instruction__item">
            Выкладываете рекламу партнера завтра, а он должен выложить вашу.
          </li>
          <li class="popup-instruction__item">Пишете отзыв о проведенном ВП.</li>
          <li class="popup-instruction__item">Повторяете процесс хоть каждый день.</li>
        </ul>
        <button
          @click="
            () => {
              infoShow = false;
              showingAuthModal(true);
            }
          "
          class="btn--orange"
        >
          Войти или зарегистрироваться
        </button>
      </div>
    </Modal>
    <modal-video
      :show="showVideo"
      @hide="hideVideoModal()"
      @showInsruction="showInstructionModal()"
    />
    <modal-instruction
      :show="showInstruction"
      @hide="hideInstructionModal()"
      @showAuth="showAuth()"
    />
    <modal-rules :show="showRules" @hide="hideRulesModal()" @showAuth="showAuth()" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Card from '@mp/components/Card/Card.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import Loader from '@main/components/Loader.vue';
import { getShowCaseProfiles } from '@mp/api/search';
import ModalVideo from './ModalVideo.vue';
import ModalInstruction from './ModalInstruction.vue';
import ModalRules from './ModalRules.vue';

export default {
  name: 'ShowCase',
  components: {
    Card,
    Modal,
    ModalVideo,
    ModalInstruction,
    ModalRules,
    Loader,
  },
  data: () => ({
    countVpWeekly: 0,
    infoShow: false,
    accounts: [],
    currentFilterId: 4,
    showVideo: false,
    showInstruction: false,
    showRules: false,
    showLoader: false,
    filters: [
      {
        id: 1,
        name: 'до 1 000',
        max: 1000,
      },
      {
        id: 2,
        name: '1 000 - 5 000',
        min: 1000,
        max: 5000,
      },
      {
        id: 3,
        name: '5 000 - 15 000',
        min: 5000,
        max: 15000,
      },
      {
        id: 4,
        name: '15 000 - 50 000',
        min: 15000,
        max: 50000,
      },
      {
        id: 5,
        name: '50 000 - 100 000',
        min: 50000,
        max: 100000,
      },
      {
        id: 6,
        name: '100 000 - 200 000',
        min: 100000,
        max: 200000,
      },
      {
        id: 7,
        name: 'от 200 000',
        min: 200000,
      },
    ],
  }),
  computed: {
    ...mapState(['currentUser', 'searchSettings', 'currentUser']),
    mobileFilter1() {
      return this.filters.filter((e) => e.id <= 4);
    },
    mobileFilter2() {
      return this.filters.filter((e) => e.id > 4);
    },
    cards() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.accounts.sort(
        (ac1, ac2) =>
          (ac2.coverage_max + ac2.coverage_min) / 2 > (ac1.coverage_max + ac1.coverage_min) / 2
      );
    },
    premiumCards() {
      return this.cards.filter((c) => c.is_premium);
    },
    defCards() {
      return this.cards.filter((c) => !c.is_premium);
    },
  },

  methods: {
    ...mapMutations('mutualpr', ['showingAuthModal']),
    async setFilter(id) {
      this.showLoader = true;
      this.currentFilterId = id;
      const filter = this.filters.filter((f) => f.id === id)[0];
      console.log('setFilter', filter);
      const { min } = filter;
      const { max } = filter;
      this.accounts = await getShowCaseProfiles(min, max);
      this.showLoader = false;
    },
    showVideoModal() {
      this.showVideo = true;
    },
    hideVideoModal() {
      this.showVideo = false;
    },
    showInstructionModal() {
      this.showVideo = false;
      this.showInstruction = true;
    },
    hideInstructionModal() {
      this.showInstruction = false;
    },
    showRulesModal() {
      this.showRules = true;
    },
    hideRulesModal() {
      this.showRules = false;
    },
    showAuth() {
      this.hideInstructionModal();
      this.hideRulesModal();
      this.showingAuthModal(true);
    },
    shortMobile(name) {
      if (name.indexOf('-') >= 0) {
        return `до${name.split('-')[1]}`;
      }
      return name;
    },
  },
  mounted() {
    this.setFilter(this.currentFilterId);
  },
};
</script>

<style scoped>
.main__item--video {
  background: transparent !important;
}
.text-center {
  text-align: center;
}
.btn--orange {
  max-width: none;
}
.main__top-mgn {
  margin-top: 50px;
}
</style>
