<template>
  <div v-if="currentUser.mutualpr_profile.is_subscribed">
    <div @click="() => $emit('show-requests')" v-if="newRequests" class="new_requests-activation">
      Новых зявок: {{ newRequests }}
    </div>
    <b-button
      variant="warning"
      type="button"
      class="btn--orange pr-active__btn js-btnPopup"
      data-popup="popupActiveSearch"
      @click="activate"
      :disabled="currentSearch && currentSearch.closed"
    >
      Найти партнеров для ВП
    </b-button>
    <p class="pr-active__text">
      Нажмите кнопку, чтобы другие партнеры смогли увидеть вашу анкету, а система подобрала вам
      аккаунты для ВП.
    </p>
    <p class="pr-active__text">
      При активации поиска до 17:00 по Москве система постарается подобрать вам до 10 потенциальных
      партнеров, а после 17:00 — всего до 7. Поэтому мы рекомендуем активировать поиск утром.
    </p>
    <p class="pr-active__text">
      Вы должны отвечать на все входящие заявки до 21:30, иначе система спишет с вас 1 из 3-х
      жизней.
      <button
        class="link-blue pr-active__link"
        data-popup="popupFine"
        @click="showFineDialog = true"
      >
        Подробнее о штрафах
      </button>
    </p>
    <modal
      :show="showActivateDialog"
      @hide="() => (showActivateDialog = false)"
      title="Выберите способ доставки уведомлений"
      wrapClass="popup-active-search__wrap step-1"
      contentClass="popup__content popup-active-search__content"
    >
      <div class="">
        <p class="popup-active-search__text">
          Чтобы активировать поиск ВП, выберите удобный способ доставки уведомлений о новых заявках
          на ВП.
        </p>
        <div class="popup-active-search__footer">
          <button type="button" class="btn--border js-btnPopup" @click="showEmailActivation = true">
            Присылать письма на почту
          </button>
          <button type="button" class="btn--border" @click="showTelegramActivation = true">
            Сообщения в телеграм
          </button>
        </div>
      </div>
    </modal>

    <modal
      :show="showFineDialog"
      @hide="showFineDialog = false"
      title="Штрафы в сервисе ВП на завтра"
      mainClass="popup-fine"
      wrapClass="popup-fine__wrap"
      contentClass="popup-fine__content"
    >
      <h2 class="popup-fine__title">За игнорирование входящих заявок</h2>
      <p class="popup-fine__text">
        Иногда, когда поиск ВП будет активен, вам будут присылать входящие запросы на ВП (тег Новая
        заявка). Отвечать на эти запросы (согласны на ВП или нет) вы должны обязательно, иначе
        система спишет с вас 1 из 3-х жизней.
        <span class="popup-fine__text--new-line"
          >При 0 жизней, сервис для вас будет недоступен до 1 числа следующего месяца.</span
        >
      </p>
      <h2 class="popup-fine__title">За обман с рекламой</h2>
      <p class="popup-fine__text">
        Человек А выложил рекламу, а человек Б не выложил рекламу. У человека Б идет блокировка на
        сайте до тех пор пока он не выложит рекламу, он не сможет брать других на рекламу и
        отправлять запросы. За этим следует отметка в его профиле, что он не выложил рекламу.
      </p>
      <h2 class="popup-fine__title">За обман со статистикой</h2>
      <p class="popup-fine__text">
        Блокировка до тех пор, пока реальная статистика не будет загружена.
      </p>
    </modal>
    <ActivateEmailNotification
      :showEmailActivation="showEmailActivation"
      @hide="showEmailActivation = false"
      @complete="() => activateSearch('email')"
    />
  </div>
  <div v-else>
    <p class="premium-update__text">Доступ к сервису истек</p>
    <button
      class="btn--orange pr-active__btn js-btnPopup premium-update__btn"
      @click="showingPriceModal"
    >
      Продлить доступ
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Modal from '@main/components/reusable/modals/Modal.vue';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import { createCurrentSearch } from '@mp/api/search';
import ActivateEmailNotification from '@main/components/reusable/modals/ActivateEmailNotification.vue';

export default {
  components: {
    Modal,
    ActivateEmailNotification,
  },
  props: {
    newRequests: {
      type: Number,
    },
  },
  data() {
    return {
      showActivateDialog: false,
      showEmailActivation: false,
      showFineDialog: false,
      links: null,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mp_search', ['currentSearch']),
  },
  methods: {
    ...mapActions('mp_search', ['loadCurrentSearch']),
    ...mapMutations('mutualpr', ['showingPriceModal']),
    ...mapMutations('notifications', ['setWindow']),
    activate() {
      if (this.currentUser.profile.email_confirmed) {
        this.activateSearch('email');
        // this.showEmailActivation = true;
      } else {
        this.showEmailActivation = true;
      }
    },
    onNotificationEmail() {
      if (this.currentUser.profile.email_confirmed) {
        // this.activateSearch('email')
        return;
      }
      this.showActivateDialog = false;
      this.showEmailActivation = true;
    },
    async activateSearch(mode) {
      this.loading = true;
      try {
        const result = await createCurrentSearch(mode);
        if (result.success) {
          this.loadCurrentSearch();
        }
      } catch (e) {
        console.log(e);
        this.setWindow({
          text: 'Не удалось создать поиск, попробуйте позднее или обратитесь в поддержку!',
          iconPath: alertGreyIcon,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
@media (max-width: 400px;) {
  .btn--orange {
    height: 60px !important;
  }
}
.pr-active__btn {
  padding: 15px !important;
  margin-bottom: 40px !important;
  max-height: 46px !important;
  max-width: none;
  line-height: 15px !important;
  display: flex;
}
.link-blue {
  display: inline !important;
}
.premium-update__text {
  text-align: center;
  margin-bottom: 30px;
}
.premium-update__btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: none !important;
  text-align: center;
  width: 75% !important;
}
.new_requests-activation {
  color: gray;
  cursor: pointer;
}
.new_requests-activation:hover {
  color: black;
  text-decoration: underline;
}
</style>
