<template>
  <div v-if="currentSearch">
    <search-vp-stories :show="showVpStories" @hide="() => (showVpStories = false)" />
    <div class="container container--mutualpr">
      <div class="def-out__top">
        <h1 class="def-out__title">ВП на завтра</h1>
        <button class="btn--white def-out__btn-add disabled">Нет аккаунтов для обновления</button>
        <b-button
          variant="outline-default"
          class="btn--search-off def-out__btn-disable js-btnPopup"
          v-if="!currentSearch.closed"
          @click="showOffSearch = true"
        >
          Отключить поиск ВП
        </b-button>
        <button
          class="btn--orange def-out__btn-prem js-btnPopup"
          @click="showByPremium = true"
          v-if="false"
        >
          Премиум от 29 ₽/день
        </button>
        <b-button
          variant="outline-default"
          class="def-out__btn-search-insta btn--search-insta js-btnPopup"
          v-if="!currentSearch.closed"
          @click="() => (showVpStories = true)"
        >
          <svg class="icon-search btn--search-insta__icon">
            <use xlink:href="../assets/sprite.svg#search"></use>
          </svg>
          Поиск ВП у меня в сторис
        </b-button>
      </div>
      <LimitedSaleOffer
        v-if="showLimitedSaleOffer"
        :endTime="premiumOffers[0].expiration"
        text="Предложение, которое вы больше никогда не увидите, исчезнет через:"
        header="Скидка на сервис от 25%"
        buttonText="Выбрать тариф"
        @action="showingPriceModal"
      />
      <div
        v-if="showWarning && currentSearch.results.length < 10 && currentSearch.updated_with_expand"
        class="offer__item offer__item--light-bg coverage_unmatch-window"
      >
        <p class="offer__text">
          В вашу выдачу были добавлены аккаунты с бо́льшим разбросом по охватам, поскольку более
          подходящие партнеры пока не зарегистрировались на ВП <br />
          <br />
          В течение дня система автоматически будет подменять такие аккаунты на более подходящие по
          охватам для вас, если они появятся в системе.
        </p>
        <div
          @click="() => (showWarning = false)"
          style="margin: 0 auto; background: white; cursor: pointer"
          class="btn--border js-btnPopup"
        >
          Окей
        </div>
      </div>
      <div>
        <!-- ACCEPTED AND PAST AND FUTURE -->
        <PrEvents
          :mutalPrs="mutalPrAccepted"
          :todayMutalPrs="mutalPrToday"
          :pastMutalPrs="mutalPrPast"
        />
        <div v-if="mutalPrAccepted.length < 2">
          <!-- PREMIUM SECTION -->
          <template>
            <div class="pr__head">
              <div class="pr__head-item">
                <div class="pr__head-item-wrap">
                  <h2 class="pr__h2">Премиум аккаунты</h2>
                  <button
                    class="btn--white pr__head-btn js-btnPopup to-desktop"
                    data-popup="popupCardDisabled"
                    v-if="premiumRejected.length > 0"
                    @click="showPremCanceled = !showPremCanceled"
                  >
                    Отклонено: {{ premiumRejected.length }}
                  </button>
                </div>
                <p
                  class="pr__descr"
                  v-if="premiumRequests.length === 0 && cardsPremium.length === 0"
                >
                  В системе не найдено подходящих аккаунтов под вашу статистику <br />
                  <span class="no__search-tip">
                    Обновление выдачи происходит каждый час - заходите позже
                  </span>
                </p>
                <p v-else class="pr__descr">
                  У этих аккаунтов есть охваты и статистика, подтвержденные скриншотами.
                </p>
              </div>
              <button
                class="btn--white pr__head-btn js-btnPopup is-desktop"
                data-popup="popupCardDisabled"
                @click="() => (showPremCanceled = !showPremCanceled)"
                v-if="premiumRejected.length > 0"
              >
                Отклоненных сегодня заявок: {{ premiumRejected.length }}
              </button>
            </div>
            <div class="card-wrapper pr__card-wrap" v-if="showCards">
              <!-- Отправленные заявки -->
              <Card
                mode="sended"
                v-for="i in premiumSended"
                :key="`${
                  i.customer.id === currentUser.id ? i.executor.id : i.customer.id
                }-searchPremSended`"
                :account="i.executor"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
              <!-- Входящие заявки -->
              <Card
                mode="requested"
                v-for="i in premiumRequests"
                :key="`${
                  i.customer.id === currentUser.id ? i.executor.id : i.customer.id
                }-searchPremReq`"
                :account="i.customer"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
              <!-- Выдача -->
              <Card
                mode="search"
                v-for="i in cardsPremium"
                :key="`${i.data.id}-searchPrem`"
                :account="i.data"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
            </div>
          </template>
          <!-- END PREMIUM SECTION -->

          <!-- DEF SECTION -->
          <template>
            <div class="pr__head">
              <div class="pr__head-item">
                <div class="pr__head-item-wrap">
                  <h2 class="pr__h2">Обычные аккаунты</h2>
                  <button
                    class="btn--white pr__head-btn js-btnPopup to-desktop"
                    data-popup="popupCardDisabled"
                    v-if="defRejected.length > 0"
                    @click="showDefCanceled = !showDefCanceled"
                  >
                    Отклонено: {{ defRejected.length }}
                  </button>
                </div>
                <p class="pr__descr" v-if="defRequests.length === 0 && cardsDef.length === 0">
                  В системе не найдено подходящих аккаунтов под вашу статистику <br />
                  <span class="no__search-tip">
                    Обновление выдачи происходит каждый час - заходите позже
                  </span>
                </p>
                <p v-else class="pr__descr">
                  У этих аккаунтов нет загруженной статистики, охваты указаны со слов блогера.
                </p>
              </div>
              <button
                class="btn--white pr__head-btn js-btnPopup is-desktop"
                data-popup="popupCardDisabled"
                @click="showDefCanceled = !showDefCanceled"
                v-if="defRejected.length > 0"
              >
                Отклоненных сегодня заявок: {{ defRejected.length }}
              </button>
            </div>
            <div class="card-wrapper pr__card-wrap" v-if="showCards">
              <!-- Отправленные заявки -->
              <Card
                mode="sended"
                v-for="i in defSended"
                :key="`${
                  i.customer.id === currentUser.id ? i.executor.id : i.customer.id
                }-searchDef`"
                :account="i.executor"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
              <!-- Входящие заявки -->
              <Card
                mode="requested"
                v-for="i in defRequests"
                :key="`${
                  i.customer.id === currentUser.id ? i.executor.id : i.customer.id
                }-searchDefReq`"
                :account="i.customer"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
              <!-- Выдача -->
              <Card
                :mode="isSended(i.data.id) ? 'sended' : 'search'"
                v-for="i in cardsDef"
                :key="`${i.data.id}-searchResults`"
                :account="i.data"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
            </div>
          </template>
          <!-- END DEF SECTION -->
          <!-- FOREIGN SECTION -->
          <template>
            <div class="pr__head">
              <div class="pr__head-item">
                <div class="pr__head-item-wrap">
                  <h2 class="pr__h2">Аккаунты из базы блогеров</h2>
                  <button
                    class="btn--white pr__head-btn js-btnPopup to-desktop"
                    data-popup="popupCardDisabled"
                    v-if="foreignRejected.length > 0"
                    @click="showForeignCanceled = !showForeignCanceled"
                  >
                    Отклонено: {{ foreignRejected.length }}
                  </button>
                </div>
                <p class="pr__descr" v-if="!!cardsForeign.length && !!foreignSended.length">
                  В системе не найдено подходящих аккаунтов под вашу статистику <br />
                  <span class="no__search-tip">
                    Обновление выдачи происходит каждый час - заходите позже
                  </span>
                </p>
                <p v-else class="pr__descr">
                  Пользователи, заполнившие статистику, но ни разу не участвовавшие в ВП.
                </p>
              </div>
              <button
                class="btn--white pr__head-btn js-btnPopup is-desktop"
                data-popup="popupCardDisabled"
                @click="showForeignCanceled = !showForeignCanceled"
                v-if="defRejected.length > 0"
              >
                Отклоненных сегодня заявок: {{ foreignRejected.length }}
              </button>
            </div>
            <div class="card-wrapper pr__card-wrap" v-if="showCards">
              <!-- Отправленные заявки -->
              <Card
                mode="sended"
                v-for="i in foreignSended"
                :key="`${
                  i.customer.id === currentUser.id ? i.executor.id : i.customer.id
                }-searchFordgnSent`"
                :account="i.executor"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
              <!-- Выдача -->
              <Card
                :mode="isSended(i.data.id) ? 'sended' : 'search'"
                v-for="i in cardsForeign"
                :key="`${i.data.id}-searchResForignSetSaw`"
                :account="i.data"
                @request="requested($event)"
                @cancelRequest="removeRequest($event)"
                @approve="approve($event)"
                @reject="reject($event)"
              />
            </div>
          </template>
          <!-- END FOREIGN SECTION -->
        </div>
      </div>
      <!-- FOOTER -->
      <OfferBox @show-vp-stories="showVpStories = true" />
      <!-- END FOOTER -->

      <deactivate-search
        :show="showOffSearch"
        @hide="() => (showOffSearch = false)"
        @searchOff="searchOff"
      />
      <modal
        :show="showForeignCanceled"
        title="Отклоненные заявки"
        @hide="showForeignCanceled = false"
        mainClass="popup-card-disabled"
        wrapClass="popup-card-disabled__wrap"
        contentClass="popup-card-disabled__content popup__content"
      >
        <div class="card-wrapper">
          <Card
            mode="canceled"
            @restore="restore(i)"
            v-for="i in foreignRejected"
            :key="`${i.id}-searchForeignRekwahdwa7`"
            :account="i"
          />
        </div>
      </modal>

      <modal
        :show="showPremCanceled"
        title="Отклоненные заявки"
        @hide="showPremCanceled = false"
        mainClass="popup-card-disabled"
        wrapClass="popup-card-disabled__wrap"
        contentClass="popup-card-disabled__content popup__content"
      >
        <div class="card-wrapper">
          <Card
            mode="canceled"
            @restore="restore(i)"
            v-for="i in premiumRejected"
            :key="`${i.id}-searchPremRej`"
            :account="i"
          />
        </div>
      </modal>
      <modal
        :show="showDefCanceled"
        @hide="showDefCanceled = false"
        title="Отклоненные заявки"
        mainClass="popup-card-disabled"
        wrapClass="popup-card-disabled__wrap"
        contentClass="popup-card-disabled__content popup__content"
      >
        <div class="card-wrapper">
          <Card
            mode="canceled"
            @restore="restore(i)"
            v-for="i in defRejected"
            :key="`${i.id}-searchDefRej`"
            :account="i"
          />
        </div>
      </modal>
      <modal
        :show="showNoPrModal"
        @hide="showNoPrModal = false"
        contentClass="nopr-contnt"
        title="Как будут подобраны партнеры"
      >
        <p style="font-size: 14px">
          Если расширить интервал в пределах
          {{
            Math.ceil(
              currentUser.blogger_profile.coverage_min -
                currentUser.blogger_profile.coverage_min * 0.5
            )
          }}
          —
          {{
            Math.ceil(
              currentUser.blogger_profile.coverage_max +
                currentUser.blogger_profile.coverage_max * 0.5
            )
          }}
          охватов сторис, то появится до {{ currentSearch.expand_count }}
          {{
            String(currentSearch.expand_count).endsWith('1') ? 'новый аккаунт' : 'новых аккаунтов'
          }}. Сейчас в интервале охватов сторис
          {{
            Math.ceil(
              currentUser.blogger_profile.coverage_min -
                currentUser.blogger_profile.coverage_min * 0.3
            )
          }}
          —
          {{
            Math.ceil(
              currentUser.blogger_profile.coverage_max +
                currentUser.blogger_profile.coverage_max * 0.3
            )
          }}
          нет аккаунтов.<br /><br />
          Вы можете расширить интервал и обновить выдачу сейчас или вернуться позже.
        </p>
        <b-button variant="warning" @click="expandSearch" class="btn--orange nopr-btn">
          Расширить интервал и обновить выдачу
        </b-button>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import literal from '@main/func/literal';
import Card from '@mp/components/Card/Card.vue';
import OfferBox from '@mp/components/offers/OfferBox.vue';
import LimitedSaleOffer from '@mp/components/offers/LimitedSaleOffer.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import * as searchApi from '@mp/api/search';
import PrEvents from './PrEvents.vue';
import SearchVpStories from './SearchVpStories.vue';
import DeactivateSearch from './DeactivateSearch.vue';

export default {
  components: {
    SearchVpStories,
    Card,
    Modal,
    DeactivateSearch,
    PrEvents,
    OfferBox,
    LimitedSaleOffer,
  },
  data() {
    return {
      showWarning: true,
      showVpStories: false,
      showOffSearch: false,
      showNoPrModal: false,
      showByPremium: false,
      showCards: false,
      showPremRejected: false,
      showPremCanceled: false,
      showDefCanceled: false,
      showForeignCanceled: false,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mutualpr', ['premiumOffers']),
    ...mapState('mp_search', ['currentSearch', 'mutalPrNew', 'mutalPrToday', 'mutalPrPast']),
    rejected() {
      return this.currentSearch.canceled;
    },
    premiumRejected() {
      return this.rejected.filter((i) => i.blogger_profile.is_premium && i.mutualpr_profile);
    },
    defRejected() {
      return this.rejected.filter((i) => !i.blogger_profile.is_premium && i.mutualpr_profile);
    },
    mutalPrIds() {
      let ids = this.mutalPrNew.map((m) => m.customer.id);
      ids += this.mutalPrNew.map((m) => m.executor.id);
      ids += this.mutalPrToday.map((m) => m.executor.id);
      ids += this.mutalPrToday.map((m) => m.customer.id);
      return ids;
    },
    cards() {
      return this.currentSearch.results.filter((v) => this.mutalPrIds.indexOf(v.id) === -1);
    },
    localCards() {
      return this.cards.filter((v) => v.mutualpr_profile);
    },
    premium() {
      return this.localCards.filter((v) => v.blogger_profile.is_premium);
    },
    other() {
      return this.localCards.filter((v) => !v.blogger_profile.is_premium);
    },
    mutalPr() {
      return this.mutalPrNew.filter((m) => m.status !== 2 && m.status !== 3);
    },
    noAcceptMp() {
      return this.mutalPr.filter((m) => m.status !== 4);
    },
    mutalPrAccepted() {
      return this.mutalPrNew.filter((m) => m.status === 4);
    },
    premiumRequests() {
      return this.noAcceptMp.filter(
        (m) =>
          m.executor.id === this.currentUser.id &&
          m.customer.blogger_profile.is_premium &&
          m.customer.mutualpr_profile
      );
    },
    defRequests() {
      return this.noAcceptMp.filter(
        (m) =>
          m.executor.id === this.currentUser.id &&
          !m.customer.blogger_profile.is_premium &&
          m.customer.mutualpr_profile
      );
    },
    premiumSended() {
      return this.noAcceptMp.filter(
        (m) =>
          m.customer.id === this.currentUser.id &&
          m.executor.blogger_profile.is_premium &&
          m.executor.mutualpr_profile
      );
    },
    defSended() {
      return this.noAcceptMp.filter(
        (m) =>
          m.customer.id === this.currentUser.id &&
          !m.executor.blogger_profile.is_premium &&
          m.executor.mutualpr_profile
      );
    },
    cardsPremium() {
      return this.premium
        .filter((profile) => !this.inMutalPr(profile))
        .map((profile) => ({
          data: profile,
        }));
    },
    cardsDef() {
      return this.other
        .filter((profile) => !this.inMutalPr(profile))
        .map((profile) => ({
          data: profile,
        }));
    },
    cardsForeign() {
      return this.cards
        .filter((profile) => !this.inMutalPr(profile))
        .filter((profile) => !profile.mutualpr_profile)
        .map((profile) => ({
          data: profile,
        }));
    },
    foreignSended() {
      return this.noAcceptMp.filter(
        (m) => m.customer.id === this.currentUser.id && !m.executor.mutualpr_profile
      );
    },
    foreignRejected() {
      return this.rejected.filter((i) => !i.mutualpr_profile);
    },
    foreignRequests() {
      return this.noAcceptMp.filter(
        (m) => m.executor.id === this.currentUser.id && !m.customer.mutualpr_profile
      );
    },
    showLimitedSaleOffer() {
      const offer = this.premiumOffers.filter((v) => v.code === 'SALE30').length > 0;
      if (!offer) {
        return;
      }
      const time = this.premiumOffers[0].expiration;
      if (!time) {
        return;
      }
      const diff = Date.parse(time) - Date.parse(new Date());
      return offer && diff > 0;
    },
  },
  methods: {
    ...mapActions('mp_search', ['removeRequest']),
    ...mapMutations('mutualpr', ['showingPriceModal']),
    isSended(profileId) {
      return (
        this.mutalPrNew.filter((m) => {
          const result = m.executor.id === profileId;
          return result;
        }).length > 0
      );
    },
    async searchOff() {
      this.showOffSearch = false;
      await searchApi.offCurrentSearch();
      this.loadCurrentSearch();
    },
    // eslint-disable-next-line no-unused-vars
    inMutalPr(profile) {
      // eslint-disable-next-line no-return-assign
      return false; // this.mutalPr.filter((pr) => pr.customer.id = profile.id).length > 0;
    },
    reject(profile) {
      searchApi.rejected(profile);
    },
    approve(profile) {
      searchApi.approve(profile);
    },
    requested(profile) {
      searchApi.requested(profile);
    },
    restore(profile) {
      if (profile.is_premium) {
        delete this.premiumRejected[this.premiumRejected.findIndex((val) => profile.id === val.id)];
        this.searchResults.push(profile);
      } else {
        delete this.defRejected[this.defRejected.findIndex((val) => profile.id === val.id)];
        this.searchResults.push(profile);
        console.log(this.defRejected.findIndex((val) => profile.id === val.id));
      }
    },
    async expandSearch() {
      this.showNoPrModal = false;
      await searchApi.updateCurrentSearch({ expand: true });
    },
  },
  async mounted() {
    this.showNoPrModal =
      this.currentSearch.results.length < 10 && !this.currentSearch.updated_with_expand;
    this.showCards = true;
  },
};
</script>

<style scoped>
.btn--orange {
  max-width: none !important;
  padding: 8.5px 15px;
}
.pr__card-wrap {
  margin-bottom: 30px;
}
.no__search-tip {
  color: gray;
  font-size: 13px;
}
.nopr-btn {
  margin-top: 25px;
}
.coverage_unmatch-window {
  float: none;
  display: flex;
  flex-direction: column;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
