<template>
  <div>
    <div class="pr-events pr-events--mgbottom" v-if="todayMutalPrs.length > 0">
      <TodayMutualPR :mutualPrs="todayMutalPrs" />
    </div>
    <div class="pr-events pr-events--mgbottom">
      <FutureMutualPR v-if="mutalPrs.length > 0" :mutualPrs="mutalPrs" />
      <PastMutualPR v-if="pastMutalPrs.length > 0" :mutualPrs="pastMutalPrs" />
    </div>
  </div>
</template>

<script>
import FutureMutualPR from './groups/FutureMutualPR.vue';
import PastMutualPR from './groups/PastMutualPR.vue';
import TodayMutualPR from './groups/TodayMutualPR.vue';

export default {
  props: {
    mutalPrs: {
      type: Array,
    },
    pastMutalPrs: {
      type: Array,
    },
    todayMutalPrs: {
      type: Array,
    },
  },
  components: {
    FutureMutualPR,
    PastMutualPR,
    TodayMutualPR,
  },
};
</script>
