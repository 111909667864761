<template>
  <div>
    <SearchView v-if="regComplete && authenticated" />
    <ShowCase v-else />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import SearchView from '../search/SearchView.vue';
import ShowCase from './ShowCase/ShowCase';

export default {
  name: 'home',
  components: { SearchView, ShowCase },
  methods: {
    ...mapMutations('mutualpr', ['setAuthentication']),
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('mutualpr', ['authenticated']),
    ...mapGetters('mutualpr', ['regComplete']),
  },
  mounted() {
    if (!this.regComplete) {
      this.setAuthentication(false);
    }
  },
};
</script>
