<template>
  <modal
    :show="showEmailActivation"
    @hide="() => $emit('hide')"
    title="Подтвердите почту"
    wrapClass="popup-active-search__wrap step-1"
    contentClass="popup__content popup-confirm-email__content"
  >
    <div v-if="activationMode">
      <div v-if="!emailSent">
        <button @click="sendActivation" class="btn--border mgn15">
          Отправить email с подтверждением
        </button>
        <p class="popup-confirm-email__text">
          Нажмите кнопку выше если вы еще не получали письмо с подтверждением
        </p>
      </div>
      <p v-else class="popup-confirm-email__text">
        На указанный e-mail было отправлено письмо с ссылкой подтверждения. Обязательно перейдите по
        ссылке из письма.
      </p>
      <div class="popup-confirm-email__footer">
        <button
          @click="checkActivation"
          class="btn--border popup-confirm-email__btn"
          v-bind:class="{ disabled: !checkActivationMode || checkButtonDisabled }"
        >
          Я подтвердил(-а) почту
        </button>
        <span class="popup-confirm-email__timer" v-if="!checkActivationMode">{{ timerCount }}</span>
        <button
          class="link-blue js-btnPopup"
          data-popup="popupConfirmEmailRetry"
          v-if="checkActivationMode"
          @click="sendAgain"
        >
          Письмо не пришло
        </button>
      </div>
      <span class="popup-confirm-email__error-msg" v-bind:class="{ show: errorActivation }"
        >E-mail не подтверждён. Попробуйте снова.</span
      >
    </div>
    <div v-else>
      <p class="popup-confirm-email__text" v-if="showEmailInfo">
        Проверьте правильность введенного e-mail, и при необходимости измените его. Затем нажмите на
        кнопку Подтвердить.
      </p>
      <p class="popup-confirm-email__text" v-if="showEmailInfo">
        Если письмо всё равно не пришло, проверьте папку спам.
      </p>
      <form @submit.prevent="sendActivation">
        <label for="email" class="label popup-confirm-email__label">Ваш e-mail</label>
        <input
          type="email"
          class="input popup-confirm-email__input"
          v-model="email"
          required
          value="korotylka@gmail.com"
        />
        <button
          class="btn--border popup-confirm-email__btn--small js-btnPopup js-btnPopup-timer"
          data-popup="popupConfirmEmailStep2"
        >
          Подтвердить
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@main/components/reusable/modals/Modal.vue';
import auth from '@main/api/auth';

export default {
  props: {
    showEmailActivation: Boolean,
  },
  components: {
    Modal,
  },
  data() {
    return {
      activationMode: false,
      email: '',
      timerCount: null,
      checkActivationMode: false,
      timer: null,
      errorActivation: false,
      checkButtonDisabled: false,
      showEmailInfo: false,
      emailSent: false,
    };
  },
  computed: {
    ...mapState(['currentUser']),
  },
  methods: {
    ...mapActions(['loadUser']),
    async initEmail() {
      if (this.currentUser.profile.email && !this.currentUser.profile.email_confirmed) {
        this.activationMode = true;
        this.checkActivationMode = true;
      }
    },
    async sendActivation() {
      await auth.sendEmail(this.email || this.currentUser.profile.email, '/settings/');
      this.errorActivation = false;
      this.checkActivationMode = true;
      this.activationMode = true;
      this.emailSent = true;
      this.timer = setInterval(() => {
        if (this.timerCount === 0) {
          this.timerCount = 5;
          this.checkActivationMode = true;
          clearInterval(this.timer);
          return;
        }
        this.timerCount -= 1;
      }, 1000);
    },
    async checkActivation() {
      this.checkButtonDisabled = true;
      await this.loadUser();
      this.checkButtonDisabled = false;
      if (this.currentUser.profile.email_confirmed) {
        this.$emit('hide');
      } else {
        this.errorActivation = true;
      }
    },
    async sendAgain() {
      this.showEmailInfo = true;
      this.activationMode = false;
    },
  },
  async mounted() {
    this.initEmail();
  },
};
</script>

<style scoped>
.show {
  display: block !important;
}
.mgn15 {
  margin-bottom: 15px;
}
</style>
