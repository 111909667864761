<template>
  <div>
    <div v-if="!vpClosed">
      <loader v-if="showLoader" />
      <div v-else>
        <Blocked v-if="blocked" />
        <div v-else>
          <SearchView v-if="currentSearch" />
          <ActivationSearch v-else-if="!currentSearch" />
        </div>
      </div>
    </div>
    <div v-else>
      <h1 style="margin-bottom: 25px" class="def-out__title">ВП на завтра</h1>
      <TrialRegistration />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Loader from '@main/components/Loader.vue';
import ActivationSearch from '@mp/search/components/ActivationSearch/ActivationSearch.vue';
import TrialRegistration from '@mp/components/offers/TrialRegistration.vue';
import SearchView from '@mp/search/components/SearchView/SearchView.vue';
import Blocked from '@mp/accounts/components/Blocked.vue';
// import * as api from './api';

export default {
  components: {
    ActivationSearch,
    SearchView,
    Loader,
    Blocked,
    TrialRegistration,
  },
  data() {
    return {
      blocked: false,
      showLoader: true,
      interval: 0,
      updateInterval: 0,
    };
  },
  computed: {
    ...mapState(['vpClosed']),
    ...mapState('mp_search', ['currentSearch']),
  },
  methods: {
    ...mapActions('mp_search', ['updateStatus', 'updateSearch']),
    initWatch() {
      this.interval = setInterval(this.updateStatus, 5000);
    },
    initSearchUpdateWatch() {
      this.updateInterval = setInterval(this.updateSearch, 30000);
    },
  },
  async mounted() {
    await this.updateStatus();
    this.initWatch();
    this.initSearchUpdateWatch();
    this.showLoader = false;
  },
  destroyed() {
    clearInterval(this.updateInterval);
    clearInterval(this.interval);
  },
};
</script>
